export async function groupDataByTimeInterval(data, intervalInSeconds) {
    const groupedData = {};
    const intervalInMillis = intervalInSeconds * 1000;
    const header = data[0];
    data = data.slice(1); // Suppression de l'en-tête des données

    const headerDate = header[0]; // La date est toujours supposée être la première colonne

    // Les noms des autres colonnes sont variables et dépendent de l'en-tête
    await data.forEach(entry => {
        const dateTime = new Date(entry[0] + 'Z'); // Assumer que la date est toujours en première position
        const baseTime = dateTime - (dateTime % intervalInMillis);

        if (!groupedData[baseTime]) {
            groupedData[baseTime] = {
                [headerDate]: new Date(baseTime)
            };

            // Initialisation des tableaux pour chaque type de données selon l'en-tête
            header.slice(1).forEach((colName, index) => {
                groupedData[baseTime][colName] = [];
            });
        }

        // Ajout des données dans les tableaux respectifs
        header.slice(1).forEach((colName, index) => {
            if (entry[index + 1] !== undefined) { // Vérification que la donnée existe
                groupedData[baseTime][colName].push(parseFloat(entry[index + 1]));
            }
        });
    });

    // Convertir l'objet en tableau et formater les dates pour être plus lisibles
    return Object.values(groupedData).map(entry => {
        const result = {
            date: entry[headerDate].toISOString().slice(0, 19).replace('T', ' ') // format "YYYY-MM-DD HH:MM:SS"
        };

        header.slice(1).forEach(colName => {
            result[colName] = entry[colName];
        });

        return result;
    });
}

export async function calculateSummaries(data) {
    return data.map(entry => {
        const summary = { date: entry.date }; // Conserver la date dans les résultats résumés
        // Parcourir chaque clé de l'objet (sauf 'date')
        Object.keys(entry).forEach(key => {
            if (key !== 'date') {
                const values = entry[key];
                // Calculer la moyenne, le min et le max pour chaque tableau
                const sum = values.reduce((acc, curr) => acc + curr, 0);
                const mean = (sum / values.length).toFixed(5);
                const min = Math.min(...values);
                const max = Math.max(...values);
                summary['MOY_' + key] = mean;
                summary['MIN_' + key] = min;
                summary['MAX_' + key] = max;
            }
        });
        return summary;
    });
}

export function convertToCSV(data) {
    if (data.length === 0) return '';
    // Créer les en-têtes à partir des clés du premier objet
    const headers = Object.keys(data[0]);
    const csvRows = [];
    // Ajouter la ligne des en-têtes
    csvRows.push(headers.join(','));
    // Ajouter chaque ligne de données
    data.forEach(row => {
        const values = headers.map(header => {
            const escaped = ('' + row[header]).replace(/"/g); // Échapper les guillemets doubles
            return `${escaped}`; // Encadrer chaque valeur avec des guillemets pour gérer les virgules et les sauts de ligne dans les valeurs
        });
        csvRows.push(values.join(','));
    });
    // Joindre toutes les lignes en une seule chaîne CSV
    return csvRows.join('\n');
}

export async function exportDataCSV(data, tag_name) {
    const csvString = convertToCSV(data);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

    // Créer un lien temporaire et lancer le téléchargement
    saveAs(blob, tag_name + '.csv');
}

export async function groupByActivityPerMinute(data) {
    const activityCounts = {};

    // Comptabiliser les activités par minute
    data.forEach(item => {
        const date = new Date(item.received_on);
        const minuteKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
        
        
        if (!activityCounts[minuteKey]) {
            activityCounts[minuteKey] = {};
        }
        
        const activity = item.activité
;
        if (!activityCounts[minuteKey][activity]) {
            activityCounts[minuteKey][activity] = 0;
        }
        
        activityCounts[minuteKey][activity]++;
    });

    // Trouver l'activité la plus fréquente pour chaque minute
    const ActivityEveryMinute = Object.keys(activityCounts).map(minuteKey => {
        const activities = activityCounts[minuteKey];
        let mostFrequentActivity = null;
        let maxCount = 0;
        
        for (const activity in activities) {
            if (activities[activity] > maxCount) {
                mostFrequentActivity = activity;
                maxCount = activities[activity];
            }
        }
        
        return {
            received_on: minuteKey,
            activité: mostFrequentActivity
        };
    });
    return ActivityEveryMinute;
}


