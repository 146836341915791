 
import {getRESTApiUri} from  './get-uri'
import {switchToken } from './test-token';
import {convertirDateAvecDecalage} from './convert-date';

export async function getActivitéSouris(tag_id, date_debut, date_fin){
    const dateDebutISO = await convertirDateAvecDecalage(new Date(date_debut));
    const dateFinISO = await convertirDateAvecDecalage(new Date(date_fin));
    const date_1 = encodeURIComponent(dateDebutISO);
    const date_2 = encodeURIComponent(dateFinISO);
    const url = getRESTApiUri() + `/get/activity/${tag_id}/date_debut/${date_1}/date_fin/${date_2}`;
    const accessToken = localStorage.getItem('accessToken'); // Assurez-vous que le token est stocké dans le localStorage
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    });
    try {
        const res = await fetch(url, { headers: headers });
        if (res.status === 401) {
            await switchToken();
            // Après avoir rafraîchi le token, refaites la requête avec le nouveau token
            await getActivitéSouris(tag_id, date_debut, date_fin);
        }
        const result = await res.text();
        const data = JSON.parse(result);
        return data; // Retournez les données ici
    } catch (error) {
        console.error(error);
        // Gérez ou renvoyez l'erreur si nécessaire
    }
};

export async function getData(tag_id, date_debut, date_fin){
    // Conversion des timestamps en strings ISO 8601
    const dateDebutISO = await convertirDateAvecDecalage(new Date(date_debut));
    const dateFinISO = await convertirDateAvecDecalage(new Date(date_fin));
    // Encodage des dates pour l'URL
    const date_1 = encodeURIComponent(dateDebutISO);
    const date_2 = encodeURIComponent(dateFinISO);
    const url = getRESTApiUri() + `/get/history-experience/${tag_id}/date_debut/${date_1}/date_fin/${date_2}`;
    const accessToken = localStorage.getItem('accessToken'); // Assurez-vous que le token est stocké dans le localStorage
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    });
    try {
        const res = await fetch(url, { headers: headers });
        if (res.status === 401) {
            await switchToken();
            // Après avoir rafraîchi le token, refaites la requête avec le nouveau token
            getData(tag_id, date_debut, date_fin);
        }
        const result = await res.text();
        const data = JSON.parse(result);
        return data; // Retournez les données ici
    } catch (error) {
        console.error(error);
        // Gérez ou renvoyez l'erreur si nécessaire
    }
};
export async function getHistoryPosition(tag_id, date_debut, date_fin) {
    const url = getRESTApiUri() + `/get/history-position`;
    const accessToken = localStorage.getItem('accessToken');

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                tag_id: tag_id,
                date_1: date_debut,
                date_2: date_fin,
            }),
        });

        if (response.status === 401) {
            // Si le token est expiré, on essaie de le rafraîchir
            const tokenUpdated = await switchToken();
            if (tokenUpdated) {
                // Refaites la requête avec le nouveau token
                return getHistoryPosition(tag_id, date_debut, date_fin);
            } else {
                throw new Error("Failed to refresh token");
            }
        }

        const result = await response.text();
        const data = JSON.parse(result);
        return data;

    } catch (error) {
        console.log(error);
    }
}

export async function getDataForExport(tag_id, date_debut, date_fin, convert) {
    // Conversion des timestamps en strings ISO 8601
    if(convert){
        date_debut = await convertirDateAvecDecalage(new Date(date_debut));
        date_fin = await convertirDateAvecDecalage(new Date(date_fin));
    }
    
    // Encodage des dates pour l'URL
    const url = getRESTApiUri() + `/export/experience/table-name`;
    const accessToken = localStorage.getItem('accessToken'); // Assurez-vous que le token est stocké dans le localStorage

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                tag_id: tag_id,
                date_1: date_debut,
                date_2: date_fin,
            }),
        });
        if (response.status === 401) {
            await switchToken();
            // Refaites la requête avec le nouveau token
            return getDataForExport(tag_id, date_debut, date_fin);
        }
        const result = await response.text();
        if (response.status === 501) {
            return false;
        }
        // Vérifiez si le résultat est du JSON avant de le parser
        try {
            const data = JSON.parse(result);
            console.log(data)
            return data;
        } catch (e) {
            console.error("Erreur de parsing JSON :", e);
            console.error("Réponse reçue :", result);
            return false;
        }
    } catch (error) {
        console.error("Erreur lors de la requête :", error);
        return false;
    }
};


export async function getDataLastId(tag_id){
    const id = encodeURIComponent(tag_id)
    const url = getRESTApiUri() + `/all/last_id/${id}`;
    const accessToken = localStorage.getItem('accessToken'); // Assurez-vous que le token est stocké dans le localStorage
    try {
        return fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
        })
            .then(res => {
            if (res.status === 401) {
                switchToken().then(() => {
                    // Refaites la requête avec le nouveau token
                    getDataLastId(tag_id);
                });
            }
            return res.text();
        })
        .then((result) => {
            const data =  JSON.parse(result)
            return data
        });
    }
    catch(error){
        console.log(error)
    }
}
export async function getMatriceAvailable (){
    const user = JSON.parse(localStorage.getItem('user'))
    const accessToken = localStorage.getItem('accessToken');
    const email = user.email
    const url = getRESTApiUri() + `/users/email/${email}`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    });
    return fetch(url, { headers: headers })
    .then(res => {
        if (res.status === 401) {
            switchToken().then(async () => {
                // Refaites la requête avec le nouveau token
                await getMatriceAvailable();
            });
        }
    })
    .then(async (result) => {
        const data = JSON.parse(result);
        return data;
    })
    .catch(error => {
        console.log(error)
    });
}
export async function getTags(){
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        const accessToken = localStorage.getItem('accessToken');
        const email = user.email;
        const url = getRESTApiUri() + `/users/email/${email}`;
        const headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        });

        const response = await fetch(url, { headers: headers });
        if (response.status === 401) {
            await switchToken();
            return getTags(); // Refaites la requête avec le nouveau token de manière récursive
        }

        const result = await response.text();
        const data = JSON.parse(result);

        // Créer un objet structuré avec toutes les données
        const tagsData = {
            implant: data.tag_attributed,
            implant_name: data.tag_renamed,
            capteur_amonniaque: data.capteur_amonniaque,
            capteur_amonniaque_name: data.amonniaque_renamed,
            capteur_vibration: data.capteur_vibration,
            capteur_vibration_name: data.vibration_renamed,
            capteur_sphere: data.capteur_sphere,
            capteur_sphere_name: data.sphere_renamed,
            capteur_environnement: data.capteur_environnement,
            capteur_environnement_name: data.environnement_renamed
        };

        return tagsData; // Retourne l'objet structuré
    } catch (error) {
        console.error("An error occurred while fetching tags:", error);
        return {}; // Retourner un objet vide en cas d'erreur
    }
}
export async function getEnv(){

}
export async function getDurationCapteurs(tag_id){
    const url = getRESTApiUri() + `/data-transmission-duration/${tag_id}`;
    try {
        const response = await fetch(url);
        const data = await response.json();
        let total_duration = 0;
        data.forEach(item => {
            total_duration += item.duration;
        });
        return total_duration;
    } catch (error) {
        console.error("Error fetching sequences:", error);
    }
}
export async function getStockage(tag_id){
    const url = getRESTApiUri() + `/list-tag/stockage/${tag_id}`;
    try {
        const response = await fetch(url);
        const data = await response.json();
        return data
    } catch (error) {
        console.error("Error fetching sequences:", error);
    }
}

export async function getNamesAndIds(attributedKey, renamedKey){
    const user = JSON.parse(localStorage.getItem('user'));
    const accessToken = localStorage.getItem('accessToken');
    const email = user.email;
    const url = getRESTApiUri() + `/users/email/${email}`;
    const headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    });

    try {
        const res = await fetch(url, { headers: headers });
        if (res.status === 401) {
            await switchToken();
            return getNamesAndIds(attributedKey, renamedKey); // Retry with new token
        }
        const result = await res.text();
        const data = JSON.parse(result);
        return data;
        
    } catch (error) {
        console.log(error);
    }
};

